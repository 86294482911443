.About {
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #8a348e 0%, #423f88 100%);
  color: white;
  justify-content: center;
}

.About-image {
  position: relative;
  opacity: 0;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.opacity {
  opacity: 0;
}

.About-img {
  width: 300px;
  position: relative;
  z-index: 9999;
  border-radius: 50%;
  box-shadow: 1px 5px 9px 3px #000;
}
.About-container {
  display: flex;
  padding: 5rem;
  align-items: center;
  justify-content: center;
}
.About-title {
  font-size: 4.2rem;
  font-weight: 400;
  margin: 0;
  color: #ffb74c;
  text-transform: uppercase;
}

.About-divider {
  border: 1px #ffb74c solid;
  margin: 2rem 0;
}

.About-content {
  font-size: 28px;
  font-weight: 100;
  line-height: 2.2rem;
}

.showLeft {
  animation: slideInFromLeft 0.5s ease-out forwards;
}

.showRight {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.inner-wrap {
  padding: 1rem;
  height: 200px;
  background-color: #783a8c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-details {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.custom-shape-divider-top-1709471783 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1709471783 svg {
  position: relative;
  display: block;
  width: calc(158% + 1.3px);
  height: 75px;
}

.custom-shape-divider-top-1709471783 .shape-fill {
  fill: #783a8c;
}

@media (width < 900px) {
  .About {
    display: block;
  }

  .About-container {
    flex-direction: column;
    padding: 3rem;
  }

  .About-img {
    width: 300px;
    height: 300px;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
