.Home {
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #8a348e 0%, #423f88 100%);
  color: white;
  justify-content: center;
}

.copy-btn {
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Home-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 7rem;
  box-shadow: 1px 5px 9px 3px #000;
}

.Home-img-2 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 7rem;
  box-shadow: 1px 5px 9px 3px #000;
  position: relative;
  right: -12px;
}

.floating-image {
  animation: floatingAnimation 2.5s ease-in-out infinite;
}

.Home-container {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  padding: 3rem;
}
.Home-divider {
  border: 1px white solid;
  margin: 2rem 0;
}

.Home-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.Home-btn {
  color: black;
  background: #e60d00;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 2rem;
  font-size: 1.25rem;
  font-weight: 700;
  flex-grow: 1;
  text-align: center;
}

.Home-iconbtns {
  margin: 3rem 0;
  display: flex;
}

.grid {
  display: grid;
}

#second-heading {
  text-shadow: 0.04419417em 0.04419417em 0.075em rgba(0, 0, 0, 1);
  animation: slideInFromLeft 0.5s ease-out forwards;
}

.Home-iconbtns a:not(:last-child) {
  margin-right: 3rem;
  max-width: 50%;
}

.showLeft {
  animation: slideInFromLeft 0.5s ease-out forwards;
}

.showRight {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.showBottom {
  animation: slideInFromBottom 0.5s ease-out forwards;
}

.popup {
  display: flex;
  align-items: center;
}

.whitespace {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 432px) and (min-width: 372px) {
  #second-heading {
    font-size: 4rem !important;
    text-align: center;
  }

  .Home-iconbtns {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 371px) {
  #second-heading {
    font-size: 2.8rem !important;
  }
}

@media (width < 900px) {
  .Home {
    display: block;
  }
  .Home-container {
    max-width: 100%;
  }

  .Home-img {
    width: 150px;
    height: 150px;
  }

  .Home-img-2 {
    width: 150px;
    height: 150px;
  }

  .mobile-column {
    flex-direction: column;
  }

  .Home-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 1rem;
    align-items: center;
  }

  .CA {
    max-width: 300px !important;
  }
}

@keyframes floatingAnimation {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(10px);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
