.Header {
  display: flex;
  background: linear-gradient(90deg, #8a348e 0%, #423f88 100%);
  color: white;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  padding: 2rem;
}

.logo-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 1px 3px 3px 2px #000;
  margin: 0 0.5rem;
}

.Header-navbar-start {
  display: flex;
  align-items: center;
  font-size: 4.5rem;
  font-weight: 100;
}
.Header-navbar-start-icon {
  width: 6.64rem;
}
.Header-navcontainer {
  display: flex;
}
.Header-navbar-center {
  display: flex;
  align-items: center;
}

.Header-navbar-center :where(li) {
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.Header-navbar-end {
  display: flex;
  gap: 2rem;
}
.Header-navbar-btn {
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.25rem;
  background-color: #8a348e;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  font-weight: 400;
}
.Header-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: max-content;
}

.Header-navbar-item {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.Header-navbar-Link {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 0rem 4rem;
}
.Header-end-menu {
  display: none;
}

@media (max-width: 600px) {
  .Header-navbar-Link {
    margin: 0 !important;
  }

  .Header-navbar-btn {
    margin-right: 0.5rem !important;
    padding: 0.5rem !important;
  }
}
@media (max-width: 900px) {
  .Header {
    justify-content: space-between;
  }

  .Header-menu {
    position: absolute;
    top: 7rem;
    right: 0;
    overflow: hidden;
    flex-direction: column;
    transition: 0.3s ease-in-out;
    transform: scale(0);
    transform-origin: top;
  }
  .Header-menu-open {
    height: max-content;
    transform: scale(1);
  }
  .Header-end-menu {
    display: flex;
    justify-self: flex-end;
    align-self: center;
    padding: 0 1rem;
  }
}
