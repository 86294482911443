@import url("https://fonts.cdnfonts.com/css/suez-one");

.Contact {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  background: #141414;
  color: white;
  text-align: center;
  padding: 2rem;
}

.Contact-title {
  font-size: 50px;
  font-weight: 400;
  margin: 0;
}
.Contact-start {
  font-family: "Suez One";
  font-size: 3.2rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Contact-start-icon {
  width: 7.9rem;
}

.Contact-iconbtns {
  margin: 3rem 0 1rem 0;
}

.Contact-iconbtns a:not(:last-child) {
  margin-right: 3rem;
  max-width: 50%;
}

.Contact-iconbtns img {
  width: 3.6rem;
}

.Contact-content {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
