html {
  font-size: 16px;
}

@media (width < 900px) {
  html {
    font-size: 14px;
  }
}
h1 {
  overflow-wrap: break-word;
}

body {
  margin: 0;
  font-family: "PixelarRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit; /* 혹은 원하는 색상으로 지정 */
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "PixelarRegular";
  src: url("../public/fonts/PixelarRegular/PixelarRegular.woff2")
      format("woff2"),
    url("../public/fonts/PixelarRegular/PixelarRegular.woff") format("woff"),
    url("../public/fonts/PixelarRegular/PixelarRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
