.Main {
  overflow: hidden;
  background-color: linear-gradient(90deg, #8a348e 0%, #423f88 100%);
}

.Main-images {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 4rem;
  height: fit-content;
}

@media (width < 900px) {
  .Main-images {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
