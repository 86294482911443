.Tokenomics {
  display: flex;
  background: linear-gradient(90deg, #8a348e 0%, #423f88 100%);
  color: white;
  justify-content: center;
  padding-top: 4rem;
}
.Tokenomics-container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  width: 100%;
  align-items: center;
}
.Tokenomics-title {
  font-size: 4.2rem;
  font-weight: 500;
  margin: 0;
  color: #ffb74c;
  opacity: 0;
  text-transform: uppercase;
}

.Tokenomics-subtitle-title {
  font-size: 3.5rem;
  font-weight: 500;
  color: #ffb74c;
}

.Tokenomics-divider {
  border: 1px #ffb74c solid;
  margin: 2rem 0;
  width: 75%;
}
.Tokenomics-subcontainer {
  display: grid;
  align-items: center;
}
.flex-container {
  opacity: 0;
  display: flex;
  justify-content: center;
  gap: 6rem;
  margin: 1.5rem 0;
}

.orange {
  color: rgb(255, 183, 76);
}

.uppercase {
  text-transform: uppercase;
}

.font-28 {
  font-size: 28px;
}

.italic {
  font-style: italic;
}
.img-container {
  border-radius: 50%;
  border: 5px #ffb74c solid;
  width: 200px;
  box-shadow: 3px 3px 5px #212121;
}

.Tokenomics-subtitle {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.2rem;
  margin: 1rem 0;
  max-width: 450px;
}
.Tokenomics-small-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.small-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-evenly;
}
.Tokenomics-content {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
}

.showRight {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.showBottom {
  animation: slideInFromBottom 0.5s ease-out forwards;
}
@media (width < 900px) {
  .Tokenomics {
    display: block;
  }
  .Tokenomics-container {
    max-width: 100%;
    padding: 0;
  }

  .flex-container {
    flex-direction: column;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
